import React, { useState, useEffect } from 'react';
import { Box, Typography, Tabs, Tab, Select, MenuItem, FormControl, InputLabel, IconButton, Button, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import ShippingList from './ShippingInfo';
import DeliveriesInfo from './DeliveriesInfo';
import deploymentsData from '../data/deployments.json';
import deliveriesData from '../data/deliveries.json';
import SCDialog from './SCDialog';

const DatacenterDeployments = ({ onClose, startOpenTab = 0, onShowAltRoutes, handleDeliveriesTabChange}) => {
    const [tabValue, setTabValue] = useState(startOpenTab);

    const [filter, setFilter] = useState('all');
    const [selectedDeployment, setSelectedDeployment] = useState('all');
    const [filteredShipments, setFilteredShipments] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalStep, setModalStep] = useState(1);
    const [selectedAction, setSelectedAction] = useState('Reroute Shipment');
    const [deliveries, setDeliveries] = useState([]);
    const [isReRouted, setIsReRouted] = useState(false);

    const deployments = deploymentsData.datacenter.deployments;

    useEffect(() => {
        const allShipments = deployments.flatMap(d => d.shipments);
        setFilteredShipments(allShipments);

        setDeliveries(deliveriesData.datacenter.deliveries);
    }, [deployments]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
       
        handleDeliveriesTabChange(newValue);
    };

    useEffect(() => { 
        setTabValue(startOpenTab);
    }, []);


    const handleFilterChange = (event) => {
        setFilter(event.target.value);
        const allShipments = deployments.flatMap(d => d.shipments);
        if (event.target.value === 'all') {
            setFilteredShipments(allShipments);
        } else {
            setFilteredShipments(allShipments.filter(s => s.status === event.target.value));
        }
    };

    const handleDeploymentChange = (event) => {
        setSelectedDeployment(event.target.value);
        if (event.target.value === 'all') {
            const allShipments = deployments.flatMap(d => d.shipments);
            setFilteredShipments(allShipments);
        } else {
            const selectedDeploymentShipments = deployments.find(d => d.id === event.target.value)?.shipments || [];
            setFilteredShipments(selectedDeploymentShipments);
        }
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setTimeout(() => setModalStep(1), 500);
    };

    const handleConfirmAction = () => {
        if (selectedAction === 'Reroute Shipment') {
            setIsReRouted(true);
            onShowAltRoutes();

        }
        handleModalClose();
    };

    const renderModalTitle = () => {
        switch (modalStep) {
            case 1:
                return (
                    <>
                        <Box>
                            <WarningIcon sx={{color:'orange'}} />
                        </Box>
                        <Typography variant='h5'>Delivery Delay Notice</Typography>
                    </>
                );
            case 2:
                return (
                    <Typography variant='h5'>Pick an action</Typography>
                );
            case 3:
                return (
                    <Typography variant='h5'>{selectedAction}</Typography>
                );
            default:
                return null;
        }
    }

    const renderModalActions = () => {
        switch (modalStep) {
            case 1:
                return (
                    <>
                        <Button variant='contained' onClick={() => setModalStep(2)}>Re-Evaluate Deliveries</Button>
                        <Button onClick={handleModalClose} autoFocus>
                            Exit
                        </Button>
                    </>
                );
            case 2:
                return (
                    <>
                        <Button onClick={handleModalClose}>Exit</Button>
                        {/* <Button variant='contained' onClick={() => setModalStep(1)}>Back</Button> */}
                        <Button variant='contained' color='success' onClick={() => setModalStep(3)}>Apply</Button>
                    </>
                );
            case 3:
                return (
                    <>
                        <Button onClick={handleModalClose}>Exit</Button>
                        <Button variant='contained' onClick={() => setModalStep(2)}>Back</Button>
                        <Button variant='contained' color='success' onClick={handleConfirmAction}>Confirm</Button>
                    </>
                );
            default:
                return null;
        }
    }

    const renderModalContent = () => {
        switch (modalStep) {
            case 1:
                return (
                    <Typography variant="body1">
                        Shipment ID: 12345<br />
                        Destination: New York<br />
                        Original ETA: 2023-06-01<br />
                        New ETA: 2023-06-05
                    </Typography>
                );
            case 2:
                return (
                    <FormControl>
                        <RadioGroup
                            defaultValue={selectedAction}
                            name="radio-buttons-group"
                            onChange={(event, value) => setSelectedAction(value)}
                            >
                            {['--Recommended', 'Reroute Shipment', '--Other Options', 'Delay Shipment', 'Re-Source Shipment', 'Swap Shipment', 'Cancel Shipment'].map((action) => (
                                (action.substring(0, 2) === '--') ? <Typography variant='h5' paddingTop="10px">{action.substring(2)}</Typography> : <FormControlLabel value={action} control={<Radio />} label={action} />
                            ))}
                        </RadioGroup>
                    </FormControl>
                );
            case 3:
                return (
                    <Box>
                       <Typography variant="body1" sx={{ mb: 2 }}>
                            Are you sure you want to {selectedAction.toLowerCase()}?
                        </Typography>
                    </Box>
                );
            default:
                return null;
        }
    };

    const updateDelivery = () => {
        //setSelectedDeliveryId(deliveryId);
        setModalOpen(true);
        setModalStep(2);
    };

    return (
        <Box sx={{ 
            width: '100%', 
            height: '100%', 
            display: 'flex', 
            flexDirection: 'column', 
            backgroundColor: '#1e1e1e', 
            overflow: 'hidden'
        }}>
            <Box sx={{ padding: '8px 16px', backgroundColor: '#333333' }}>
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        top: 4,
                        right: 4,
                        padding: '4px',
                        backgroundColor: '#444444',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#555555',
                        },
                    }}
                >
                    <CloseIcon fontSize="small" />
                </IconButton>

                <Typography variant="h5" sx={{ fontSize: '24px', mb: 1, color: 'white', paddingRight: '40px' }}>
                    Shipments & Deliveries
                </Typography>
                
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Tabs value={tabValue} onChange={handleTabChange} sx={{ minHeight: '36px' }}>
                        <Tab label="Shipments" sx={{ color: 'white', minHeight: '36px', padding: '6px 12px' }} />
                        <Tab label="Deliveries" sx={{ color: 'white', minHeight: '36px', padding: '6px 12px' }} />
                    </Tabs>
                    
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <FormControl sx={{ width: '190px', mr: 2 }} size="small">
                            <InputLabel id="deployment-label" sx={{ color: 'white' }}>Select Deployment</InputLabel>
                            <Select
                                labelId="deployment-label"
                                value={selectedDeployment}
                                onChange={handleDeploymentChange}
                                sx={{
                                    color: 'white',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(255, 255, 255, 0.23)',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(255, 255, 255, 0.23)',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(255, 255, 255, 0.23)',
                                    },
                                }}
                            >
                                <MenuItem value="all">All</MenuItem>
                                {deployments.map((deployment) => (
                                    <MenuItem key={deployment.id} value={deployment.id}>{deployment.id}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        
                        <FormControl sx={{ width: '120px' }} size="small">
                            <InputLabel id="filter-label" sx={{ color: 'white' }}>Filter</InputLabel>
                            <Select
                                labelId="filter-label"
                                value={filter}
                                onChange={handleFilterChange}
                                sx={{
                                    color: 'white',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(255, 255, 255, 0.23)',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(255, 255, 255, 0.23)',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(255, 255, 255, 0.23)',
                                    },
                                }}
                            >
                                <MenuItem value="all">All</MenuItem>
                                <MenuItem value="ontime">On Time</MenuItem>
                                <MenuItem value="delayed">Delayed</MenuItem>
                                <MenuItem value="arrived">Arrived</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
            </Box>
            
            <Box sx={{ flexGrow: 1, overflow: 'auto', pl: 2, pr: 0, py: 1, height: 'calc(100% - 100px)' }}>
                {tabValue === 0 && <ShippingList shipments={filteredShipments} />}
                {tabValue === 1 && <DeliveriesInfo deliveries={deliveries} delayedClickHandler={updateDelivery} reRouted={isReRouted} />}
            </Box>

            <SCDialog 
                modalOpen={modalOpen}
                title={renderModalTitle()}
                content={renderModalContent()}
                actions={renderModalActions()}
                onClose = {(event, reason) => {
                    if (reason !== 'backdropClick') {
                        handleModalClose();
                    }
                }} 
            />
        </Box>
    );
};

export default DatacenterDeployments;